.ctn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 50px;
  background: transparent;
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  color: white;
}

.logo{
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img{
  width: 100px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

.menu{
  display: flex;
  align-items: center;
  justify-content: center;
  gap : 25px;
}

.menu a{
  font-weight: 400;
  font-size: .9rem;
}

.getStarted{
  padding: 8px 15px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 0.9rem;
  background: #00000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  margin-left: 20px;
  transition: all 0.3s ease;
}

.getStarted:hover{
  background: #ffffff;
  color: #000000;
}

.login{
  padding: 7px 18px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  font-weight: 400;
  font-size: 0.9rem;
  background: #ffffff;
  color: #000000;
  transition: all 0.3s ease;
}

.login:hover{
  background: #ffffff00;
  color: #ffffff;
}

.hamburger{
  display: none;
}
  
.bar{
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  transition: all 0.3s ease;
}

  
.activeBar{
  display: block;
  width: 25px;
  height: 4px;
  margin: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}




@media screen and (max-width: 1100px){
.ctn{
  padding: 0 10px;
}
}

@media screen and (max-width: 800px){
.logo img{
  width: 100px;
  padding-top: 0;
}

.menu{
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80vw;
  background: #222831;
  color: rgb(207, 207, 207);
  padding-top: 40px;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  z-index: 10000;
  transition: all ease-in-out .5s;
  font-size: 1rem;
}

.menu a{
  font-size: 1.1rem;
}


.hamburger{
  display: block;
  cursor: pointer;
  transition: all ease-in-out .5s;
  position: relative;
  z-index: 100000;
}

.bar{
  background: #fff !important;
  border-radius: 2px;
  transition: all ease-in-out .5s;
}

.bar:nth-child(2){
  width: 15px;
  margin-left: auto;
}

.activeBar{
  width: 22px;
  background: #080808;
  border-radius: 2px;
  transition: all ease-in-out .5s;
}

.activeBar:nth-child(1){
  transform: rotate(45deg) translate(0, 6px);
}

.activeBar:nth-child(2){
  display: none;
}

.activeBar:nth-child(3){
  transform: rotate(135deg) translate(0, 6px);
}

.getStarted{
  margin-left: 0;
}

.getStarted:hover{
  background: none;
  color: rgb(0, 103, 176);
}

.menu a:hover{
  color: rgb(0, 103, 176);
}

  
}