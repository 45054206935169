.container{
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  background: whitesmoke;
}

.container h1, h2, h3, p{
  color: black;
}

.side{
  width: 15%;
  height: 100%;
}

.main{
  width: 85%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 20px;
}


@media screen and (max-width: 1100px){
.side{
  width: 25%;
}

.main{
  width: 75%;
}
}

@media screen and (max-width: 650px){
.container{
  flex-direction: column-reverse;
}

.side{
  width: 100%;
  height: 7vh;
  position: fixed;
  bottom: 0;
  z-index: 10000;
}

.main{
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  padding-bottom: 50px;
}

}