.ctn {
  width: 100%;
  height: 70vh;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 37, 46, 0.969), rgba(0, 63, 79, 0.8)), url("../../assets/world.png");
  background-size: cover; 
  color: white !important;
  display: grid;
  place-items: center;
}

.wrapper {
  width: 100%;
  height: fit-content;
  max-width: 1170px;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.heading{
  font-size: 3.5rem;
  font-weight: 300;
}

.header span{
  color: #84ff00;
}

.subHeading{
  font-size: 1.1rem;
  font-weight: 300;
  color: rgb(209, 209, 209);
}

@media screen and (max-width: 700px){
.heading{
  font-size: 1.3rem;
  font-weight: 500;
}

.subHeading{
  font-size: 1rem;
}
}