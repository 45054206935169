.ctn{
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  align-items: center;
  background-color: #00000056;
}

.wrp{
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}

.title{
  color: rgb(221, 221, 221);
  font-size: 6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.1;
  letter-spacing: -2px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.title span{
  color: #00b35f;
  font-style: oblique;
}

.subtitle{
  width: 100%;
  max-width: 800px;
  font-size: 1.5rem;
  font-weight: 100;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}


@media screen and (max-width: 768px){
.ctn{
  background-size: 250%;
  height: 60vh;
}

.wrp{
  width: 100%;
}

.title{
  font-size: 11vw;
  font-weight: 600;
}

.subtitle{
  font-size: 14px;
  display: none;
}
}