.ctn{
  width: 100%;
  padding: 100px 0;
}

.ctn h1{
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  font-size: 2rem;
  font-weight: 300;
}

.wrp{
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
}

.slide{
  width: 98.3% !important;
  max-width: 530px !important;
  height: fit-content !important;
  border-radius: 10px;
  overflow: hidden;
  position: relative !important;
  background-color: rgba(113, 113, 113, 0.1);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
  padding: 30px 20px;
  margin-right: 20px;
}

.slide p{
  font-size: .8rem;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

.avatar{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.name{
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #ffffff;
}

.avatar img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #76c8eb24;
}

.quote{
  position: absolute;
  top: 30px;
  right: 30px;
}

.quote path{
  fill: #76c8eb1c;
}





@media screen and (max-width: 600px){
.ctn{
  padding: 100px 10px;
}

.avatar img{
  width: 40px;
  height: 40px;
}

.slide{
  width: 100vw;
  margin-right: 10px;
}

.quote{
  width: 30px;
  height: 30px;
  top: 35px;
  right: 10px;
}

}