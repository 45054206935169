.ctn{
  width: 100%;
  padding: 100px 0;
}

.wrp{
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.wrp img{
  width: 100%;
}