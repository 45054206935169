.upload{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border: .5px solid rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 7px 3px;
  cursor: pointer;
}

.upload p{
  font-size: 0.9rem;
  position: absolute;
  left: 10px;
  z-index: 1;
}

.upload input{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  padding: 1.5rem;
}

.upload svg{
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.5);
}

.checkbox{
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox input{
  width: 15px;
  height: 15px;
}

.checkbox p{
  font-size: 0.85rem;
}

.link{
  text-align: center;
  font-size: 0.9rem;
  color: rgb(0, 36, 36);
  transition: all .3s ease;
}

.link:hover{
  color: rgb(40, 185, 124);
}