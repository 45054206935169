.link, .link2{
  text-align: center;
  font-size: 0.9rem;
  color: rgb(0, 36, 36);
  transition: all .3s ease;
}

.link2{
  font-size: 0.8rem;
  color: rgb(0, 0, 0);
  margin-top: -10px;
}

.link:hover{
  color: rgb(40, 185, 124);
}