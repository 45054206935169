.container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}

.hello{
  display: flex;
  align-items: center;
  gap: 10px;
}

.hello p{
  font-size: 20px;
}

.logo{
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  transition: all ease-in-out .3s;
}

.logo svg{
  color: black !important;;
}

.image{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  overflow: hidden;
}

.image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu{
  position: absolute;
  top: 10px;
  right: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 20px;
  font-size: 20px;
  color: black;
  background: rgb(255, 255, 255);
  border-radius: 7px;
  transition: all ease .3s;
  z-index: 1000;
}

.menu a:hover{
  color: rgb(0, 120, 80);
  transition: all ease-in-out .1s;
  cursor: pointer;
}

.exit{
  color: black;
}

.modal{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  transition: all .3s ease;
}

.modalcontent{
  width: 330px;
  background: white;
  border-radius: 15px;
}

.modalcontent form{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.modalcontent h1{
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 20px;
}

.btns{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.submit{
  background: black !important;
  color: white;
  font-size: .8rem;
}

.back{
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: .8rem;
  padding: 12px 15px;
  border-radius: 30px;
  border: none;
  transition: all ease .3s;
}

.back:hover{
  background: rgb(0, 120, 80);
  transition: all ease .3s;
}

.cancel:hover{
  color: rgba(0, 55, 207, 0.8);
  cursor: pointer;
}

.error{
  font-size: .8rem;
  color: rgb(255, 35, 11);
}

.modalSuccess{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.modalSuccessContainer{
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.modalSuccessContainer h1{
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
}

.transaction{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  z-index: 200000;
  overflow-y: scroll;
  background: whitesmoke;
}

.transaction_item{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.transaction_item_left{
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.transaction_item p:nth-child(1){
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.9);
}

.transaction_item p:nth-child(2){
  font-size: .7rem;
  color: rgba(0, 0, 0, 0.7);
  word-wrap: break-word;
}

.transaction_item p:nth-child(3){
  font-size: .7rem;
  color: rgba(0, 0, 0, 0.6);
}

.transaction_item_right{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
}

.transaction_item_right h3{
  font-size: 1.5rem;
  text-align: end;
  color: #ffa200;
}

.transaction_item_right p{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.transaction_item_right p svg{
  font-size: 1.3rem;
}

.exit{
  margin: 7px 5px;
}


@media screen and (max-width: 600px){
.modalcontent{
  width: 90%;
}

.hello{
  padding-left: 50px !important;
}
  
}