.ctn{
  width: 100%;
  padding: 50px 0;
}

.wrapper{
  max-width: 1170px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 20px;
}

.header{
  text-align: center;
  margin-bottom: 5rem;
  font-size: 5rem;
  font-weight: 400;
}

.card{
  max-width: 330px;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  border-bottom: 2px solid #005c31;
  padding-bottom: 20px;
}

.card:hover{
  transform: translateY(-0.5rem);
  border-bottom: 2px solid #00b35f;
}

.icon {
  font-size: 3rem;
  color: #005c31;
  margin-bottom: 1rem;
}

.title{
  font-size: 1.3rem;
  margin-bottom: 20px;
  font-weight: 500;
}

.desc{
  line-height: 1.8;
  font-size: 18px;
  font-weight: 200;
  color: #dedede
}

@media screen and (max-width: 500px){
.header{
  font-size: 1.8rem;
}

.card{
  max-width: 100%;
  width: 100%;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
}

}