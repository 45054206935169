.ctn {
  width: 100%;
  padding: 150px 0;
  background-image: linear-gradient(180deg, #000000d5, #000000d5),
    url(../../assets/map.jpg);
  background-attachment: fixed;
  background-size: cover;
  overflow-x: scroll;
}

.wrapper {
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
}

.heading {
  width: fit-content;
  font-size: 2.5rem;
  font-weight: 300;
  background-color: #141414;
  padding: 30px;
  border-radius: 30px;
  margin: 0 auto;
}

.tracking_div {
  min-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.track_heading {
  font-size: 1.4rem;
  font-weight: 400;
  color: whitesmoke;
}

.track_heading span {
  font-weight: 300;
}

.tracking_info p {
  font-size: 1.1rem;
  font-weight: 300;
  color: whitesmoke;
}

.tracking_info p span {
  font-size: 1.2rem;
  font-weight: 100;
}

.image.png table {
  width: 100%;
}

.tracking_div thead {
  width: 100%;
  background-color: rgb(214, 214, 214);
}

.tracking_div thead th {
  padding: 12px;
  font-size: 1.3rem;
  font-weight: 400;
  color: black;
  border-radius: 5px;
}

.tracking_div tbody {
  width: 100%;
  background-color: #141414;
}

.tracking_div tbody td {
  padding: 12px;
  font-size: 1.1rem;
  font-weight: 300;
  color: rgb(234, 234, 234);
  text-align: center;
}

.tracking_info_extra {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
}

.tracking_info_extra p {
  width: 100%;
  color: whitesmoke;
  font-size: 1.1rem;
  font-weight: 300;
}

.tracking_info_extra p span {
  font-weight: 100;
}

@media screen and (max-width: 768px) {
  .ctn {
    padding: 100px 0;
  }

  .wrapper {
    max-width: 100%;
    padding: 0 15px;
  }

  .heading {
    font-size: 4.5vw;
    padding: 20px;
    border-radius: 20px;
  }
}
