.container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  padding-top: 100px;
}

.CryptoFund{
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.53);
  border-radius: 20px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 2px 2px 10px #00000013;
  margin-top: 150px;
}

.qr{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.address{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(201, 201, 201, 0.2);
  border-radius: 20px;
  padding: 10px;
}

.icon{
  font-size: .5rem;
  color: #00b35f;
}

.qr input{
  width: 100%;
  word-wrap: break-word;
  padding: 10px;
  font-size: .9rem;
  border: none;
}

.qr input:active{
  border: none;
}

.imgCtn{
  display: grid;
  place-items: center;
}

.qr img{
  max-width: 105%;
  height: auto;
  object-fit: contain;
  align-self: center;
}


.text{
  width: 100%;
}

.text p{
  width: 100%;
  font-size: .9rem;
  color: #000000e4;
}

.text p span{
  font-weight: 600;
}

.uploadCtn{
  width: 300px;
  min-height: 300px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
}

.imgCtn2{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.imgCtn2 img{
  width: 100%;
  border-radius: 10px;
}

.btns2{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.amount{
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

.amount h2{
  font-size: 2.5rem;
  font-weight: 300;
}

.amount div div{
  border-radius: 10px !important;
}

.navigation{
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 2000;
  position: absolute;
  left: 0;
  top: 0;
}

.current{
  display: flex;
  gap: 10px;
  padding-top: 20px;
}

.current svg{
  color: wheat;
}


@media only screen and (max-width: 500px) {
.container{
  padding: 0px;
  height: auto;
}

.CryptoFund{
  padding: 50px 10px 100px 10px;
  margin-top: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: none;
}

.uploadCtn{
  width: 100%;
  margin-top: 50px;
}

.imgCtn2{
  height: auto;
  padding-bottom: 70px;
}

.amount{
  width: 100%;
  padding: 50px 10px;
}

}