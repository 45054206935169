.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  transition: all 0.3s ease-in-out;
}

.content {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 0.25rem;
  position: relative;
  padding: 1.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.close {
  position: absolute;
  top: 1;
  right: 2rem;
  transform: translate(50%, -50%);
  font-size: 2.5rem;
  background: transparent;
  border-color: transparent;
  color: #00ff00;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close{
  color: #0066ff;
}

.heading{
  margin-bottom: 0.5rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.details {
  margin-bottom: 2.5rem;
  font-weight: 400;
  border-bottom: 1px solid #0066ff;
  padding-bottom: 0.5rem;
}

.item {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem;
}

.content h2 {
  margin-bottom: 1rem;
}

.loc{
  color: black;
  font-weight: bold;
}

.time {
  color: #0066ff;
  font-weight: 500;
}

.icon {
  font-size: 1.6rem;
  color: grey;
  margin-right: 0.8rem;
}

.icon::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 1.5rem;
  background-color: grey;
  margin: 0 1rem;
}