.ctn{
  width: 100%;
  height: auto;
  margin: 170px 0 40px 0;
}

.wrp{
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrp img{
  width: 100%;
}



@media screen and (max-width: 950px){
.ctn{
  margin: 60px 0 30px 0;
}

}