.container{
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.balCard{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: space-around;
  gap: 15px;
}

.card{
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  background: rgb(255, 255, 255);
  padding: 15px;
  margin-bottom: 20px;
  gap: 15px;
}

.cardheader{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardtitle{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 7px;
}

.cardtitle h3{
  font-size: .9rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.isactive{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(310deg, #000000 0%, #4d5424 100%);
  border-radius: 10px;
}

.circle{
  font-size: 1.8rem;
  color: white;
}

.cardbody{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardbody h1{
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
  font-weight: 500;
  letter-spacing: -2px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.cardbody h1 span{
  font-size: 1.2rem;
  font-family: 'Courier New', Courier, monospace;
}


.chart{
  font-size: 1.5rem;
}

@media screen and (max-width: 600px){
.container{
  min-height: 500px;
}

.balCard{
  padding: 0 10px;
  gap: 10px;
}

.card{
  width: 100%;
  padding: 10px;
  margin-bottom: 0px;
  gap: 0px;
}

}